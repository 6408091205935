<template>
  <div
    ref="bSelectCheckbox"
    class="dropdown noselect"
    style="margin-right: 20px"
    tabindex="1"
    :class="{ disabled }"
  >
    <div class="select" :class="{ open: showDropdown }" @click="toggleDropdown">
      <div class="selected-opt-wrapper">
        <span class="selected-opt-header">{{ header ? `${header}: ` : '' }}</span>
        <span class="selected-opt">{{ displaySelected }}</span>
      </div>
      <font-awesome-icon
        :icon="['far', showDropdown ? 'chevron-up' : 'chevron-down']"
        class="droparw"
      />
    </div>
    <ul v-if="showDropdown" :id="dropdownId" class="dropdown-menu">
      <li
        v-for="(option, i) in optionsCopy"
        :key="i"
        :class="[
          option.showStar ? 'rollup-option' : 'norm-option',
          option.disabled ? 'disabled' : '',
          option.selected ? 'active' : '',
          option.children && option.open ? 'has-children open' : 'has-children',
        ]"
      >
        <div
          class="main"
          @click="toggleOption(i)"
          @mouseover="(el) => handleMouseOver(el, i + 1, option.event)"
          @mouseleave="handleMouseLeave"
        >
          <font-awesome-icon
            class="check"
            :class="{ 'check-off': !option.selected, wl: applyWhiteLabeling }"
            :icon="['fa', 'check']"
            size="6x"
          />
          <span class="norm-option-item">{{ option.event }}</span>
          <font-awesome-icon
            v-if="option.children && option.children.length"
            :icon="['far', option.open ? 'chevron-up' : 'chevron-down']"
            class="droparw"
            @click.stop="toggleChildren(i)"
          />
        </div>
        <ul v-if="option.children && option.open" class="sub">
          <li
            v-for="(child, ci) in option.children"
            :key="ci"
            class="child-option"
            @click.stop="toggleChildOption(i, ci)"
            @mouseover="(el) => handleMouseOver(el, i + 1, child.event, ci + 1)"
            @mouseleave="handleMouseLeave"
          >
            <font-awesome-icon
              class="check"
              :class="{ 'check-off': !child.selected, wl: applyWhiteLabeling }"
              :icon="['fa', 'check']"
              size="6x"
            />
            <span>{{ child.event }} </span>
          </li>
        </ul>
      </li>
    </ul>
    <div :style="activeEl.style" class="tooltipText">
      {{ activeEl.text }}
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import { uuid } from '~/helpers/global/misc-helpers';

export default {
  props: {
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    header: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableDropDownSelectOption: {
      type: Boolean,
      required: false,
      default: false,
    },
    enableOptionClickSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    applyWhiteLabeling: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    let optChildLength = 0;
    if (this?.options?.[0]?.children?.length > 0) {
      optChildLength = this.options[0].children.length;
    }
    return {
      showDropdown: false,
      optionsCopy: JSON.parse(JSON.stringify(this.options)),
      dropdownId: uuid(),
      activeEl: { style: { display: 'none' }, text: '' },
      currSelectedOption: {
        optionIndex: 1,
        childrenLength: optChildLength,
      },
    };
  },
  computed: {
    isMniAccount: get('common/isMniAccount'),

    displaySelected() {
      const selectedOptions = this.optionsCopy
        .filter((opt) => opt.selected || opt.children?.some((child) => child.selected))
        .map((opt) =>
          opt.selected
            ? opt.event
            : opt.children
                .filter((c) => c.selected)
                .map((c) => c.event)
                .join(', ')
        )
        .join(', ');
      return selectedOptions.length > 0 ? selectedOptions : 'Select';
    },
  },
  watch: {
    options: {
      deep: true,
      handler(newOptions) {
        this.optionsCopy = JSON.parse(JSON.stringify(newOptions));
        if (this.optionsCopy[0]?.children) {
          this.currSelectedOption = {
            optionIndex: 1,
            childrenLength: this.optionsCopy[0].children.length,
            open: this.optionsCopy[0].open,
          };
        }
      },
    },
  },
  mounted() {
    window.addEventListener('click', this.onClickOutside);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutside);
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    toggleOption(index) {
      const option = this.optionsCopy[index];
      if (!option.disabled) {
        option.selected = !option.selected;

        // If parent is selected, select all children
        if (option.selected && option.children && option.children.length) {
          option.children.forEach((child) => {
            child.selected = true;
          });
        }
        // If parent is deselected, deselect all children
        else if (!option.selected && option.children && option.children.length) {
          option.children.forEach((child) => {
            child.selected = false;
          });
        }

        this.$emit('selected-options', this.optionsCopy);
      }
    },
    toggleChildren(index) {
      this.optionsCopy.forEach((item, i) => {
        if (i !== index) item.open = false;
      });
      this.optionsCopy[index].open = !this.optionsCopy[index].open;
      if (this.optionsCopy[index].open) {
        this.currSelectedOption = {
          optionIndex: index + 1,
          childrenLength: this.optionsCopy[index]?.children?.length || 0,
          open: !this.optionsCopy[index].open,
        };
      } else {
        this.currSelectedOption = {};
      }
    },
    toggleChildOption(parentIndex, childIndex) {
      const parent = this.optionsCopy[parentIndex];
      const child = parent.children[childIndex];
      child.selected = !child.selected;

      // If any child is selected, select the parent
      if (parent.children.some((c) => c.selected)) {
        parent.selected = true;
      }
      // If no child is selected, deselect the parent
      else {
        parent.selected = false;
      }

      // Emit selection change to parent component
      this.$emit('selected-options', this.optionsCopy);
    },
    onClickOutside(e) {
      if (!this.$refs.bSelectCheckbox.contains(e.target)) {
        this.showDropdown = false;
        this.$emit('dropdown-closed'); // Callback when dropdown is closed
      }
    },
    changeSelection(optionIndex) {
      this.optionsCopy.forEach((option, oi) => {
        option.selected = oi === optionIndex;
        if (oi !== optionIndex) {
          if (option.children) {
            option.children.forEach((child) => {
              child.selected = false;
            });
          }
        }
        option.children = option.children || [];
      });
      if (!this.disableDropDownSelectOption) {
        this.showDropdown = false;
      }
    },
    handleMouseOver(el, i, text, subTextIndex = 0) {
      const ulElement = document.getElementById(this.dropdownId);
      let addedDiv = 0;
      if (subTextIndex) {
        addedDiv = subTextIndex;
      } else {
        const { childrenLength, optionIndex, open } = this.currSelectedOption;
        if (optionIndex < i && open) {
          addedDiv = childrenLength;
        }
      }
      if (!text) {
        return;
      }
      this.activeEl = {
        style: {
          top: `${(i + addedDiv) * 39.9 - ulElement.scrollTop + 40}px`,
          left: `${25}px`,
          display: 'block',
        },
        text,
      };
    },
    handleMouseLeave() {
      this.activeEl = { style: { display: 'none' }, text: '' };
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;

  &.child-open {
    // box-shadow: 0px 10px 17px -6px rgb(0 0 0 / 40%);
  }
}
.light-theme {
  .dropdown .dropdown-menu > li:hover {
    background-color: rgba(0, 0, 0, 0.04);
    ul {
      li {
        background-color: rgba(255, 255, 255, 0.04);
      }
    }
  }
  .check {
    color: #5b6165;
    box-shadow: 0px 0px 2px #484848;
    background: #ffffff;
  }
  ul li svg {
    color: #212325;
    fill: #212325;
    &.check-off {
      color: transparent !important;
    }
  }
  .norm-option.active.has-children {
    color: #212325 !important;
    background-color: rgb(128 143 167 / 14%) !important;
    box-shadow: unset !important;
  }
  .sub {
    background-color: rgb(128 143 167 / 8%);
    color: #536176 !important;
    box-shadow: unset !important;
    li {
      &:first-child {
        box-shadow: inset 0px 9px 6px -3px rgba(0, 0, 0, 0.2);
      }
      border-top: unset;
      border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    }
  }
}
.check {
  float: left;
  width: 1em;
  padding: 2px;
  margin-right: 0.5rem;
  color: var(--defaultprimarycolor);
  border-radius: 30px;
  box-shadow: inset 0px 0px 1px 1px #2aa4af70;
  &.wl {
    color: var(--primarycolor) !important;
    &.check-off {
      color: transparent !important;
    }
  }
}
.check-off {
  color: transparent !important;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.selected-opt-header {
  font-size: 14px;
  color: #707e8a;
}
.selected-opt-wrapper {
  display: inline-flex;
  width: 90%;
  white-space: nowrap;
}
.selected-opt {
  display: inline-block;
  width: 90%;
  margin-left: 0.2rem;
  overflow: hidden;
  font-size: 14px;
  color: #cad1d6;
  text-overflow: ellipsis;
  white-space: nowrap;
}
svg.reverse {
  transform: rotate(180deg);
}
svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-w-14 {
  width: 0.875em;
}
.svg-inline--fa.fa-w-18 {
  width: 1.125em;
}
svg {
  float: right;
}
ul li svg {
  position: relative;
  font-size: 18px;
  color: #cbccd2;
  border: none;
  fill: #cbccd2;
}

@media screen {
  * {
    box-sizing: border-box;
  }
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  * {
    outline: 0;
  }
  .dropdown {
    position: relative;
    display: inline-block;
    min-width: 200px;
    height: 100%;
    font-size: 14px;
    color: #fff;
    text-align: left;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    border-radius: 4px;
    transition: all 0.3s ease;
    -webkit-touch-callout: none;
  }
  .light-theme {
    .selected-opt {
      color: #212325;
    }
    .dropdown {
      .dropdown-menu {
        background-color: #f9f9f9;
        li {
          color: #555;
        }
      }
      .select {
        background-color: #ffffff;
        border: 1px solid #c5d2d8;
        color: #555;
        &.open {
          background-color: #ffffff;
        }
      }
    }
  }
  .dropdown .select {
    display: block;
    min-height: 40px;
    padding: 8px 10px;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid rgba(170, 170, 170, 0.1);
    border-radius: 4px;
    &.open {
      background-color: #383a44;
    }
  }
  .droparw {
    position: relative;
    top: 5px;
    float: right;
    font-size: 11px !important;
    line-height: 12px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  .dropdown .dropdown-menu {
    position: absolute;
    left: 0;
    z-index: 9;
    width: 100%;
    max-height: 250px;
    margin-top: 0;
    overflow: hidden;
    overflow-y: auto;
    background-color: rgb(45, 46, 53, 0.98);
    border-radius: 0 1px 4px 4px;
    box-shadow: 0 5px 3px 1px rgba(0, 0, 0, 0.1);
  }
  .dropdown .dropdown-menu li {
    padding: 10px;
    font-size: 13px;
    color: #cad1d6;
    cursor: pointer;
    border-top: 1px solid rgba(255, 255, 255, 0.06);
    outline: none;
    transition: all 0.2s ease-in-out;
    svg {
      font-size: 17px;
    }
  }
  .dropdown .dropdown-menu li.norm-option {
    // display: flex;

    span {
      width: 89%;
      padding-right: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & > .sub {
      margin-top: 0.5rem;
    }
    ul {
      padding: 0;
      margin: 10px -10px 0 -10px;
      li {
        padding: 10px 20px;
        margin: 0;
        display: flex;
        &:last-child {
          padding: 10px 20px 10px 20px;
        }
        &:hover {
          background-color: rgba(255, 255, 255, 0.04);
        }
      }
    }
  }
  .norm-option.active.has-children {
    background-color: rgba(0, 0, 0, 0.14) !important;
    // border-top: 3px solid rgba(255, 255, 255, 0.1) !important;
    box-shadow: 0px 10px 17px -6px rgb(0 0 0 / 40%) !important;
  }
  .dropdown .dropdown-menu {
    padding: 0;
    list-style: none;
  }
  .dropdown .dropdown-menu > li:hover {
    background-color: rgba(255, 255, 255, 0.04);
    ul {
      li {
        background-color: rgb(45, 46, 53, 0.98);
      }
    }
  }
  .dropdown .dropdown-menu > li:active {
    background-color: #333;
    ul {
      li {
        background-color: rgb(45, 46, 53, 0.98);
      }
    }
  }
  .active.has-children.open {
    padding-bottom: 0 !important;
  }
  .roll-preset {
    display: inline-block;
    padding: 0 4px;
    margin-right: 6px;
    font-size: 10px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    background-color: var(--defaultprimarycolor);
    border-radius: 12px;
  }
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(111, 111, 111, 0.2);
    border: 0 #fff;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #525965;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #525965;
  }
  ::-webkit-scrollbar-track {
    background: 0 0;
    border: 0 #fff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: 0 0;
  }
  ::-webkit-scrollbar-track:active {
    background: 0 0;
  }
  ::-webkit-scrollbar-corner {
    background: 0 0;
  }
}

.fa-chevron-down:before {
  content: '\f078';
}
.fa-star:before {
  content: '\f005';
}

.fa-chevron-down:before {
  content: '\f078';
}
.fa-star:before {
  content: '\f005';
}
.tooltipText {
  position: absolute;
  z-index: 999;
  width: auto;
  font-size: 12px;
  color: #222;
  pointer-events: none;
  background-color: rgba(245, 245, 250, 0.9);
  text-align: center;
  border-radius: 3px;
  transition: ease 0.3s;
  line-height: 1.2em;
  transform-origin: bottom center;
  padding: 5px;
}
</style>
